import React from 'react';
import { sendGALinkClickEvent } from '@noths/polaris-client-google-analytics';
import { Breadcrumb } from '@noths/polaris-client-next-components';

import * as styles from './styles';
import type { BreadcrumbWrapperProps } from './types';

export const BreadcrumbWrapper = (props: BreadcrumbWrapperProps) => {
  return (
    <div css={styles.breadcrumbWrapper}>
      <Breadcrumb
        {...props}
        onBreadcrumbLinkClick={(e, { linkDepth }) => {
          sendGALinkClickEvent(e, {
            event: 'custom_event',
            event_action: 'Link Click',
            event_category: 'Breadcrumb',
            event_label: `${linkDepth} Level(s) Up`,
            destination_URL: e.currentTarget.href,
          });
        }}
      />
    </div>
  );
};
