import type {
  BreadcrumbItems,
  BreadcrumbJSONLDSchema,
} from '@noths/polaris-client-next-components';
import { toSentenceCase } from '@noths/polaris-client-utils';
import { createSelector } from '@reduxjs/toolkit';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import {
  selectPartnerHomepageRelativeUrl,
  selectPartnerHomepageUrl,
  selectPartnerName,
} from 'src/redux/products/selectors';
import { PageType } from 'src/types/navigation';

const selectAndDecorateBreadcrumbData = (state: ReduxApplicationState) =>
  state.navigation.pageType === PageType.Partner
    ? selectAndDecoratePartnerBreadcrumbData(state)
    : selectAndDecorateCategoryBreadcrumbData(state);

const selectAndDecoratePartnerBreadcrumbData = createSelector(
  selectPartnerName,
  selectPartnerHomepageUrl,
  selectPartnerHomepageRelativeUrl,
  (partnerName, partnerHomepageUrl, partnerHomepageRelativeUrl) => [
    { rel: '/', title: 'Homepage', href: new URL(partnerHomepageUrl).origin },
    {
      rel: partnerHomepageRelativeUrl,
      title: partnerName,
      href: partnerHomepageUrl,
    },
    { rel: null, title: 'Products', href: null },
  ],
);

const selectAndDecorateCategoryBreadcrumbData = ({ products }: ReduxApplicationState) =>
  products.category!.hierarchy.map(({ href, rel, title }) => ({
    rel,
    href,
    title: (title && toSentenceCase(title)) ?? '',
  }));

export const selectBreadcrumbJSONLDSchema = createSelector(
  selectAndDecorateBreadcrumbData,
  (hierarchy): BreadcrumbJSONLDSchema => ({
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: hierarchy.map(({ href, title }, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: title,
      item: href || undefined,
    })),
  }),
);

export const selectBreadcrumbData = createSelector(
  selectAndDecorateBreadcrumbData,
  (hierarchy): BreadcrumbItems =>
    hierarchy.map(({ href, rel, title }) => ({
      href: href ?? '',
      rel: rel ?? '',
      title,
    })),
);
