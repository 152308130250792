import {
  fontSize,
  minBreakpoints,
  responsiveFontSize,
  theme,
} from '@noths/polaris-client-ribbons-base';
import { mediaQuery, pxToRem } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { fonts, spacing, text } = theme;

export const partnerHeaderWrapper = css`
  text-align: center;
  margin: 0 auto ${pxToRem(spacing.xl)};
  max-width: ${pxToRem(628)};

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      margin: 0 auto 0;
    `,
  )};
`;

export const partnerImage = css`
  display: block;
  margin: 0 auto ${pxToRem(spacing.xs)};
  width: ${pxToRem(60)};
  border-radius: ${pxToRem(10)};

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      width: ${pxToRem(76)};
    `,
  )};
`;

export const headingSuffix = css`
  font-family: ${fonts.PRIMARY_SEMIBOLD.family};
  font-weight: ${fonts.PRIMARY_SEMIBOLD.weight};
  ${responsiveFontSize({
    XXS: text.HEADING.LG,
    L: text.HEADING.XL,
  })};
  display: block;
`;

export const partnerName = css`
  font-family: ${fonts.PRIMARY_REGULAR.family};
  font-weight: ${fonts.PRIMARY_REGULAR.weight};
  ${fontSize(text.BODY.MD)};
  color: var(--neutral-black-base);
  display: block;
`;
