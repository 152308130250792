/* istanbul ignore file */

import React from 'react';
import { useVisibilityObserver } from '@noths/polaris-client-react-hooks';
import type { ClickCarouselLinkPayload } from '@noths/polaris-client-ribbons-design-system';
import { PillBarCarousel } from '@noths/polaris-client-ribbons-design-system';

import * as styles from 'src/components/organisms/Filter/styles/QuickFilters.styles';
import type {
  BrowseDataAPIFilter,
  BrowseDataAPIFilterOption,
} from 'src/services/browse-data-api/types/BrowseDataAPIFilter';

const QUICK_FILTERS_VISIBILITY_THRESHOLD = 0.7;

export interface OnClickOptionArgs {
  clickedOption: BrowseDataAPIFilterOption;
}

export interface QuickFiltersStateProps {
  filter: BrowseDataAPIFilter;
  onVisible?: () => void;
}

export interface QuickFiltersDispatchProps {
  onClickOption: (onClickOptionArgs: OnClickOptionArgs) => void;
}

export type QuickFiltersProps = QuickFiltersStateProps & QuickFiltersDispatchProps;

export const QuickFilters = ({ filter, onClickOption, onVisible }: QuickFiltersProps) => {
  const { observedElementRef: quickFiltersRef } = useVisibilityObserver(
    () => {
      onVisible?.();
    },
    { observerOptions: { threshold: QUICK_FILTERS_VISIBILITY_THRESHOLD } },
  );
  const links = filter.options.map((option) => {
    return {
      href: '',
      text: option.title!,
      active: option.active,
    };
  });

  const handleLinkClick = (
    evt: React.MouseEvent<HTMLAnchorElement>,
    { index }: ClickCarouselLinkPayload,
  ) => {
    evt.preventDefault();

    onClickOption({
      clickedOption: filter.options[index],
    });
  };

  return (
    <div css={styles.quickFilters} ref={quickFiltersRef}>
      <PillBarCarousel links={links} onLinkClick={handleLinkClick} title="Quick filters" />
    </div>
  );
};
