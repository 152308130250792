import { minBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import { mediaQuery, stack } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { spacing } = theme;

export const breadcrumbWrapper = css`
  margin: ${stack(spacing.xl)};

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      margin: ${stack(spacing.xxl)};
    `,
  )};
`;
