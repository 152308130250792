/* istanbul ignore file */
import { connect } from 'react-redux';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { BreadcrumbWrapper } from './BreadcrumbWrapper';
import { selectBreadcrumbData, selectBreadcrumbJSONLDSchema } from './modules/selectors';
import type { BreadcrumbWrapperProps } from './types';

export const mapStateToProps = (state: ReduxApplicationState): BreadcrumbWrapperProps => ({
  JSONLDSchema: selectBreadcrumbJSONLDSchema(state),
  breadcrumbItems: selectBreadcrumbData(state),
});

export const BreadcrumbContainer = connect(mapStateToProps)(BreadcrumbWrapper);
