/* istanbul ignore file */
import React, { useEffect } from 'react';
import type { ConnectedProps } from 'react-redux';
import { batch, connect } from 'react-redux';
import type { CookieObj } from '@noths/polaris-client-gdpr-compliance';
import { acceptsFunctionalCookies } from '@noths/polaris-client-gdpr-compliance';
import { parseLocaleSetCookieHeaders } from '@noths/polaris-client-localisation';
import * as recommendations from '@noths/polaris-client-recommendations';
import { grid, maxWidthContainer } from '@noths/polaris-client-ribbons-base';
import { visuallyHidden } from '@noths/polaris-client-styles';
import {
  actions as userConfigurationActions,
  getOrSetLoop54UserId,
  getOrSetRichRelevanceSessionId,
  getUserAttributes,
} from '@noths/polaris-client-user-configuration';
import { addRUMTiming } from '@noths/polaris-client-utils';
import { StatusCode } from '@noths/polaris-dev-ts-types';
import type { GetServerSideProps } from 'next';
import getConfig from 'next/config';
import Head from 'next/head';
import { parse } from 'url';

import { BackToTopButton } from 'src/components/molecules/BackToTopButton/BackToTopButton';
import { BreadcrumbContainer } from 'src/components/molecules/Breadcrumb/BreadcrumbContainer';
import { ListingsApiErrorGuardContainer } from 'src/components/molecules/ListingsApiErrorGuard/ListingsApiErrorGuardContainer';
import { MembershipBannerSection } from 'src/components/molecules/MembershipBannerSection/MembershipBannerSection';
import { PartnerHeader } from 'src/components/molecules/PartnerHeader/PartnerHeader';
import { FilterContainer } from 'src/components/organisms/Filter/containers/FilterContainer';
import { QuickFiltersContainer } from 'src/components/organisms/Filter/containers/QuickFiltersContainer';
import { SlimFilterMenuContainer } from 'src/components/organisms/FilterMenu/containers/SlimFilterMenuContainer';
import { ProductPagesContainer } from 'src/components/organisms/ProductPages/containers/ProductPagesContainer';
import { RecommendedProductsShelfContainer } from 'src/components/organisms/RecommendedProductsShelf/containers/RecommendedProductsShelfContainer';
import * as elementIds from 'src/constants/elementIds';
import { Placement } from 'src/constants/recommendations';
import { useFilterNavType } from 'src/hooks/useFilterNavType/useFilterNavType';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { actions as navigationActions } from 'src/redux/navigation/slice';
import {
  selectPartnerHomepageUrl,
  selectPartnerLogoUrl,
  selectPartnerMetaTagDescription,
  selectPartnerName,
} from 'src/redux/products/selectors';
import { requestProducts } from 'src/redux/products/thunks/requestProducts';
import { PARTNER_NOT_FOUND_ERROR } from 'src/redux/products/thunks/thunkErrors';
import type { ProductsState } from 'src/redux/products/types';
import { recommendedProductsRequestComplete } from 'src/redux/richRelevance/actions';
import { requestRecommendedProducts } from 'src/redux/richRelevance/thunks';
import {
  selectCanonicalTag,
  selectPagePropsForClientHydration,
  selectPaginationTagValues,
} from 'src/redux/selectors';
import { getStore, useStore } from 'src/redux/store';
import { fetchGlobalContent } from 'src/services/contentstack';
import * as redirection from 'src/services/redirection';
import * as styles from 'src/styles/pages.styles';
import { PageType } from 'src/types/navigation';
import type { NextConfig } from 'src/types/nextConfig';
import type { PageProps } from 'src/types/page';
import { getFeatureFlagsWithMatchers } from 'src/utils/getFeatureFlagsWithMatchers';

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig() as NextConfig;

const mapStateToProps = (state: ReduxApplicationState) => ({
  products: state.products,
  partnerName: selectPartnerName(state),
  partnerLogoUrl: selectPartnerLogoUrl(state),
  metaTagDescription: selectPartnerMetaTagDescription(state),
  partnerHomepageUrl: selectPartnerHomepageUrl(state),
  canonicalTag: selectCanonicalTag(state),
  paginationTagValues: selectPaginationTagValues(state),
  pageLiveRegionText: state.accessibility.statusMessage,
});

const connector = connect(mapStateToProps);

type PartnerProductsPageProps = ProductsState & PageProps & ConnectedProps<typeof connector>;

const PartnerProductsPage = ({
  canonicalTag,
  functionalCookiesConsented,
  metaTagDescription,
  pageLiveRegionText,
  paginationTagValues,
  partnerHomepageUrl,
  partnerLogoUrl,
  partnerName,
  updatedReduxState,
}: PartnerProductsPageProps) => {
  const { dispatch } = useStore();
  const [prevMetaTag, nextMetaTag] = paginationTagValues;

  useFilterNavType((navType) => dispatch(navigationActions.setInitialPageLoad(navType)));

  useEffect(() => {
    getUserAttributes()
      .then((attributes) =>
        recommendations.initRecommendations({
          apiKey: publicRuntimeConfig.RICH_RELEVANCE_API_KEY,
          env: process.env.NODE_ENV,
          hasDisabledCookies: !functionalCookiesConsented,
          sessionId: updatedReduxState?.userConfiguration?.richRelevanceSessionId,
          userId: attributes?.sub,
        }),
      )
      .then(async () => {
        recommendations.setPartnerProductsPage(partnerName);

        const placementData = await recommendations.getRecommendations([
          Placement.PartnerListingsRecentlyViewed,
        ]);

        const recommendedProductsRequests = placementData.map((placement) =>
          dispatch(requestRecommendedProducts(placement)),
        );

        await Promise.allSettled(recommendedProductsRequests);
        addRUMTiming('recommendationsLoaded');
      })
      .catch((e) => {
        console.warn(
          `Could not load recommendations on partner product listings page: ${e.message}`,
        );
      })
      .finally(() => {
        dispatch(recommendedProductsRequestComplete());
      });
  });

  return (
    <>
      <Head>
        <title>{partnerName} | Products</title>
        <link href={canonicalTag} rel="canonical" />
        {prevMetaTag && <link href={prevMetaTag} rel="prev" />}
        {nextMetaTag && <link href={nextMetaTag} rel="next" />}
        <meta content={metaTagDescription} name="description" />
        <meta content={partnerName} property="og:title" />
        <meta content="profile" property="og:type" />
        <meta content={partnerLogoUrl} property="og:image" />
        <meta content={partnerHomepageUrl} property="og:url" />
      </Head>
      <ListingsApiErrorGuardContainer>
        <div css={visuallyHidden} role="status">
          {pageLiveRegionText}
        </div>
        <div css={styles.wrapper}>
          <div css={[grid, maxWidthContainer]}>
            <div css={styles.breadcrumbContainer}>
              <BreadcrumbContainer />
              <PartnerHeader partnerLogoUrl={partnerLogoUrl} partnerName={partnerName} />
            </div>
          </div>
          <div id={elementIds.PRODUCT_CONTAINER_WRAPPER}>
            <SlimFilterMenuContainer />
            <div css={styles.quickFiltersWrapper}>
              <QuickFiltersContainer />
            </div>
            <div css={[grid, maxWidthContainer, styles.productListWrapper]}>
              <div css={styles.productListSidebar}>
                <FilterContainer />
              </div>
              <div css={styles.productPagesWrapper}>
                <ProductPagesContainer />
                <RecommendedProductsShelfContainer
                  placementType={Placement.CategoryListingsRecentlyViewed}
                />
                <MembershipBannerSection
                  buttonHref="/membership"
                  buttonText="Tell me more"
                  copy="Join our <strong>NOTHS & More</strong> membership and get 10% off every single order – including sale."
                  heading="Get 10% off any of these amazing finds"
                  trackingLocation="partner"
                />
                <BackToTopButton />
              </div>
            </div>
          </div>
        </div>
      </ListingsApiErrorGuardContainer>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async ({ query, req, res }) => {
  const { partnerId, ...queryParams } = query;

  if (partnerId) {
    const pathname = parse(req.url!).pathname!;
    const { cognitoId } = req.cookies;
    const { dispatch, getState } = getStore(
      {
        router: { pathname, query: queryParams },
      },
      {
        navigation: {
          pageType: PageType.Partner,
        },
        user: {
          cognitoID: cognitoId || null,
        },
      },
    );
    const { Loop54User, currency, delivery_zone, rr_session } = req.cookies;
    const functionalCookiesConsented = acceptsFunctionalCookies(req.cookies as CookieObj);
    const richRelevanceSessionId = getOrSetRichRelevanceSessionId(res, rr_session);
    const featureFlags = (req && getFeatureFlagsWithMatchers(req)) || {};
    const { currency: initialCurrency, deliveryZone: initialDeliveryZone } =
      parseLocaleSetCookieHeaders(res.getHeaders());

    batch(() => {
      dispatch(navigationActions.setPathname({ url: req.url ?? '', host: req.headers.host ?? '' }));
      dispatch(userConfigurationActions.setCurrencyCode(initialCurrency ?? currency));
      dispatch(userConfigurationActions.setDeliveryZone(initialDeliveryZone ?? delivery_zone));
      dispatch(userConfigurationActions.setFeatureFlags(featureFlags));
      dispatch(userConfigurationActions.setRichRelevanceSessionId(richRelevanceSessionId));

      if (functionalCookiesConsented) {
        const loop54UserId = getOrSetLoop54UserId(res, Loop54User);
        dispatch(userConfigurationActions.setLoop54UserId(loop54UserId));
      }
    });

    const productsQuery = {
      ...queryParams,
      shortcode: partnerId,
    };

    const urlPath = req.url;
    const [, [navData, topBannerData]] = await Promise.all([
      dispatch(
        requestProducts({
          query: productsQuery,
        }),
      ),
      fetchGlobalContent({ featureFlags, urlPath }),
    ]);

    const state = getState();
    const { products } = state;

    const redirect = redirection.productGuard(products, query, pathname);

    if (redirect) {
      return redirect;
    }

    if (products.error) {
      res.statusCode =
        products.error.name === PARTNER_NOT_FOUND_ERROR.name
          ? StatusCode.NotFound
          : StatusCode.InternalServerError;

      if (res.statusCode === StatusCode.InternalServerError) {
        res.setHeader('Retry-After', serverRuntimeConfig.retryAfterDuration);
      }

      const props: PageProps = {
        navData,
        functionalCookiesConsented,
        updatedReduxState: {
          // Cherry pick state to be updated on the client
          products,
          navigation: { pageType: PageType.Partner },
        },
      };

      return {
        props,
      };
    }

    const props: PageProps = {
      featureFlags,
      navData,
      topBannerData,
      functionalCookiesConsented,
      ...selectPagePropsForClientHydration(state),
    };

    return {
      props,
    };
  } else {
    const props: PageProps = {
      updatedReduxState: {
        products: {
          error: {
            message: 'An unknown error occurred',
          },
        },
      },
    };

    return {
      props,
    };
  }
};

export default connector(PartnerProductsPage);
